import React, { useCallback, useEffect, useState, createContext } from "react";
import jwtDecode from "jwt-decode";
let logoutTimer;
const AuthContext = createContext({
  token: "",
  isLoggedIn: false,
  isVerified: false,
  getEmail: "",
  image: "",
  setImage: "",
  email: (email) => {},
  login: (token, expiresIn) => {},
  logout: () => {},
  admin: (admin) => {},
  isUserVerified: (isVerifiedUser) => {},
  isAdmin: false,
  google_id: "",
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  const storedExpirationDate = localStorage.getItem("expirationTime");
  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();
  let initialToken;
  if (tokenData) {
    initialToken = tokenData.token;
  }
  const [token, setToken] = useState(initialToken);

  const storedExpirationDate = localStorage.getItem("expirationTime");
  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    localStorage.clear();
  }

  //!
  const initialIsVerified = localStorage.getItem("isVerified");
  const [getIsVerified, setIsVerified] = useState(initialIsVerified);

  //!

  const initialEmail = localStorage.getItem("email");
  const [getEmail, setEmail] = useState(initialEmail);

  const initialAdmin = localStorage.getItem("admin");
  const [getAdmin, setAdmin] = useState(initialAdmin);

  const initialImage = localStorage.getItem("image");
  const [image, setImage] = useState(initialImage);

  const initialGoogleId = localStorage.getItem("google_id");
  const [googleId, setGoogleId] = useState(initialGoogleId);

  const userIsLoggedIn = !!token;

  const LogoutHandler = useCallback(() => {
    setToken(null);
    setIsVerified(null);
    localStorage.clear();

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const LoginHandler = (token, expiresIn) => {
    setToken(token);
    localStorage.setItem("token", token);
    localStorage.setItem("expirationTime", expiresIn);
    setImage(jwtDecode(token).profile_image);
    setIsVerified(true);

    const remainingTime = calculateRemainingTime(expiresIn);
    logoutTimer = setTimeout(LogoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(LogoutHandler, tokenData.duration);
    }
  }, [tokenData, LogoutHandler]);

  const emailHandler = (email) => {
    setEmail(email);
    localStorage.setItem("email", email);
  };

  const adminHandler = (admin) => {
    if (admin === 1) {
      admin = true;
    } else {
      admin = false;
    }
    setAdmin(admin);
    localStorage.setItem("admin", admin);
  };

  const isVerifiedHandler = (isVerifiedUser) => {
    if (isVerifiedUser) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
    localStorage.setItem("isVerified", isVerifiedUser);
  };
  const googleIdHandler = (id) => {
    setGoogleId(id);
    localStorage.setItem("google_id", id);
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    getEmail: getEmail,
    isAdmin: getAdmin,
    image: image,
    isVerified: getIsVerified,
    google_id: googleId,
    adminLogin: adminHandler,
    email: emailHandler,
    login: LoginHandler,
    logout: LogoutHandler,
    isUserVerified: isVerifiedHandler,
    googleHandler: googleIdHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
