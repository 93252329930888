import axios from "axios";
import ConfigData from "../../config";

const setAxiosToken = () => {
  axios.defaults.headers.common["token"] = localStorage.getItem("token");
};
setAxiosToken();
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = ConfigData.URL;

export { setAxiosToken };
export default axios;
