import React from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./Footer/footer.scss";
import { useContext } from "react";
import AuthContext from "../store/AuthContext";
import { useLocation } from "react-router-dom";
import ScrollToTop from "./scrollTop/ScrollToTop";
import { GoogleOAuthProvider } from "@react-oauth/google";

const Layout = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { isLoggedIn } = useContext(AuthContext);
  const { pathname } = useLocation();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <ScrollToTop />

        <div className="outer-container">
          {queryParams.get("hideHeaderFooter") == "true" ? null : (
            <Header userPic={props.userPic} />
          )}
          <div className="mb-5"></div>

          <div className="main-content" style={{ flexGrow: 1 }}>
            {props.children}
          </div>
          {isLoggedIn ||
          pathname === "/" ||
          pathname === "/privacy-policy" ||
          pathname === "/verify" ||
          pathname === "/contact-us" ? (
            <div className="footer-bg-color" style={{ marginTop: "auto" }}>
              {queryParams.get("hideHeaderFooter") == "true" ? null : (
                <Footer isLoggedIn={isLoggedIn} />
              )}
            </div>
          ) : null}
        </div>
        {/* )} */}
      </GoogleOAuthProvider>
    </LocalizationProvider>
  );
};

export default Layout;
