import React from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "../ProfilePage/Profile.scss";
import { TextField as FormikTextField } from "formik-mui";
import { styled } from "@mui/styles";
import { TextField } from "@mui/material";
import Button from "../../components/Button/Button";

const CustomTextField = styled(FormikTextField)({
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
});

const EditProfileName = (props) => {
  const initialValues = {
    name: props.name,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Must be at least 3 characters")
      .max(30, "Name must be less than 30 characters")
      .matches(/[A-Za-z]/, "Must be a valid name")
      .required("Please enter name"),
  });

  return (
    <div className="col-lg-6 col-md-12 my-auto mx-auto">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={props.onSubmit}
      >
        {(formik) => (
          <Form className="card-body mx-xl-5">
            <div>
              <div className="d-flex col-12">
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  className=" col-12 my-3"
                />
              </div>
            </div>
            <div>
              <div className="d-flex col-12">
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  name="email"
                  onChange={formik.handleChange}
                  value={props.email}
                  disabled
                  className="col-12 my-3"
                />
              </div>
            </div>
            <div class="col-md-12 text-md-end">
              <Button
                text="Cancel"
                className="btn btn-outline-secondary me-2"
                onClick={() => props.setEditProfileName(false)}
              />
              <Button
                type="submit"
                text="Save changes"
                className="btn btn-success"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditProfileName;
