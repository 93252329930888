import React, { useState, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Badge from "@mui/material/Badge";
import axios from "../../assets/axiosConfig/AxiosConfig";
import ConfigData from "../../config";
import authContext from "../../store/AuthContext";
import Loading from "../../components/loading/Loading";
import ProfileImageSrcComponent from "../../components/ProfileImageSrcComp.jsx/ProfileImageSrcComponent";
import useGetUserId from "../../assets/customHooks/getUserId";
import { toast } from "react-toastify";

function ProfileImage(props) {
  const authCtx = useContext(authContext);
  const userId = useGetUserId();
  const [image, setImage] = useState(authCtx.image);
  const [loader, setLoader] = useState(false);
  const max_size = 1024; // 1MB
  const notifyError = (msg) => toast.error(msg);

  const upload = (file, fileType) => {
    setLoader(true);
    axios
      .post(ConfigData.uploadProfileImageURL, {
        data: file,
        userId: userId,
        fileType: fileType,
      })
      .then(async (res) => {
        setImage(res.data);
        localStorage.setItem("image", res.data);
        props.setUserPic(!props.userPic);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        // notifyError("Something went wrong, please try again later");
        notifyError(err.response.data);
      });
  };

  const uploadImage = (file) => {
    const fileType = file.type;

    const fileSizeKiloBytes = file.size / 1024;
    if (fileSizeKiloBytes < max_size) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        upload(reader.result, fileType);
      };
    } else {
      notifyError("Image size must be less than 1MB (1024KB)");
    }
  };

  return (
    <React.Fragment>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        badgeContent={
          loader ? (
            <Loading />
          ) : props.contactProfileToggle ? null : (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              sx={{
                color: "black",
                background: "white",
                border: "1px solid",
              }}
            >
              <EditIcon />
              <input
                accept="image/*"
                id="file"
                type="file"
                hidden
                onChange={(e) => {
                  uploadImage(e.target.files[0]);
                }}
              />
            </IconButton>
          )
        }
      >
        <ProfileImageSrcComponent
          ParentImage={props.contactProfileToggle ? props.contactImage : image}
          alt="default image"
          sx={{ width: "10rem", height: "10rem" }}
          contactProfileToggle={props.contactProfileToggle}
        />
      </Badge>
    </React.Fragment>
  );
}

export default ProfileImage;
