import React from "react";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";

import { MenuProps, useStyles } from "./utils";

function ShowContactsModal(props) {
  const { contacts, selectedContacts, setSelected } = props;
  const classes = useStyles();

  const isAllSelected =
    contacts.length > 0 && selectedContacts.length === contacts.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selectedContacts.length === contacts.length ? [] : contacts);
      return;
    }
    setSelected(value);
  };

  const handleClose = () => {
    props.setContactsModal(false);
  };

  return (
    <Modal
      centered
      backdrop="static"
      show={props.contactsModal}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="modal-heading">{props.title}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.body}</p>
        <Formik initialValues={{}} onSubmit={props.onSubmit}>
          {(formik) => (
            <Form>
              <FormControl className={classes.formControl}>
                <InputLabel id="multiple-select-label">
                  Select Contacts
                </InputLabel>
                <Select
                  id="contacts"
                  name="contacts"
                  labelId="multiple-select-label"
                  multiple
                  variant="standard"
                  value={selectedContacts}
                  onChange={handleChange}
                  renderValue={(selectedContacts) =>
                    selectedContacts
                      .map((item) =>
                        item.names ? item.names[0]?.displayName : item
                      )
                      .join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selectedContacts.length > 0 &&
                          selectedContacts.length < contacts.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {contacts.map((contact, index) => (
                    <MenuItem key={index} value={contact}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedContacts.indexOf(contact) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={contact.names[0].displayName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="text-end me-2 mt-2">
                <button className="btn btn-success mb-2" type="submit">
                  Save Changes
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default ShowContactsModal;
