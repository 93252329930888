import React from "react";
import { useHistory } from "react-router-dom";
import "../Footer/footer.scss";
import { WEBSITE_LOGO, appEmail } from "../../assets/Constants/appConstants";

function Footer({ isLoggedIn }) {
  const history = useHistory();
  const { email } = appEmail;

  const scrollToComponent = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <div className="container footer-bg-color footer">
      <div className="row">
        <div className="col-lg-6">
          <div>
            <img
              className="footer-img"
              src={WEBSITE_LOGO.logo}
              style={{ width: 250 }}
              alt="Social Squad"
            />
            <p className="img-text">
              Helping people connect with their friends and family. Plan and
              share their events.
            </p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 footer-text">
          <h3>GET IN TOUCH</h3>
          <ul className="p-0">
            <li className="addressText footer-li">Redmond, Washington U.S.A</li>
            <li className="footer-text footer-li">
              <a href={`mailto:${email}`} className="emailClass">
                {email}
              </a>
            </li>
          </ul>
        </div>
        <div className="col-lg-2 col-md-12 footer-text">
          <h3>INFO</h3>
          <ul className="p-0">
            <li
              className="footer-li"
              onClick={() => history.push("/privacy-policy")}
            >
              Privacy Policy
            </li>
            {/* <li
              className="footer-li"
              // onClick={() => scrollToComponent("/contact-us")}
              onClick={() => history.push("/contact-us")}
            >
              Contact Us
            </li> */}
            {isLoggedIn ? (
              <li
                className="footer-li"
                onClick={() => history.push("/feature-request")}
              >
                Feature Request
              </li>
            ) : null}
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 copyright">© 2024 OnyxTec</div>
      </div>
    </div>
  );
}

export default Footer;
