import React from "react";
import { escapeRegExp } from "lodash";

function DataTableSearch(props) {
  return (
    <input
      type={props.type}
      placeholder={props.placeholder}
      className={props.className}
      value={props.search}
      onChange={(e) => {
        props.setSearch(escapeRegExp(e.target.value));
      }}
    ></input>
  );
}

export default DataTableSearch;
