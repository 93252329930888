function Loading() {
  return (
    <div className="spinner-border text-center text-dark my-5" role="status">
      <span className="sr-only" hidden>
        Loading...
      </span>
    </div>
  );
}

export default Loading;
