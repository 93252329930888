import React, { createContext, useState } from "react";

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  const [fetchNotificationList, setFetchNotificationList] = useState(false);

  return (
    <NotificationContext.Provider
      value={{ fetchNotificationList, setFetchNotificationList }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
