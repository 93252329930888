import React from "react";
import Modal from "react-bootstrap/Modal";
import "../UpcommingEvents/Modal.scss";

function DeleteModal(props) {
  const handleClose = () => {
    props.setDeleteModal(false);
  };

  const functionHandler = () => {
    if (props.functionState) {
      props.function();
      handleClose();
    } else {
      props.setRefreshProfileCanceledEvents(
        !props.refreshProfileCanceledEvents
      );
      handleClose();
    }
  };

  return (
    <div>
      <Modal centered show={props.deleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className="modal-heading">{props.title}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        <div className="d-flex justify-content-end mt-3 mb-2 me-2">
          <button
            type="button"
            class="btn btn-secondary me-2"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-danger modal-button"
            onClick={functionHandler}
          >
            {props.title}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default DeleteModal;
