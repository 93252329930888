import React from 'react'
import { ToastContainer} from "react-toastify";
function ToastNotification() {
  return (
    <ToastContainer
          position="top-center"
          autoClose={800}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
  )
}

export default ToastNotification