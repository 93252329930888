import web_logo from "../images/websiteLogo.svg";
import web_icon from "../images/website-icon.svg";

import bts1 from "../images/bts1.jpg";
import bts2 from "../images/bts2.jpg";
import bts3 from "../images/bts3.jpg";
import bts4 from "../images/bts4.jpg";
import icon1 from "../images/icon1.png";
import icon2 from "../images/icon2.jpg";
import icon3 from "../images/icon3.jpg";
import icon4 from "../images/icon4.jpg";
import ad1 from "../images/ad1.svg";
import ad2 from "../images/ad2.svg";
import ad3 from "../images/ad3.svg";
import bike from "../images/appmobile.png";
import group1 from "../images/group1.png";
import group2 from "../images/group2.png";
import group3 from "../images/group3.png";
export const BTSectionSliderData = {
  btsHeading: "Key Features",
  btsText:
    "Discover the tools that make event planning a breeze.",

  SliderData: [
    {
      img: bts1,
      heading: "Check Availability",
      text: "Easily see when your contacts are free and find a time that suits everyone's schedule.",
    },
    {
      img: bts2,
      heading: "Import Contacts",
      text: "Seamlessly transfer your contacts from your phone's address book to start scheduling meetings right away.",
    },
    {
      img: bts3,
      heading: "Create Groups",
      text: "Organize your contacts into groups for easy management and efficient scheduling.",
    },
    {
      img: bts4,
      heading: "Personalized Calendar",
      text: "Effortlessly keep track of all your meetings, appointments, and events in one convenient place.",
    },
  ],
};

export const FeatureComponentData = {
  featureText:
    "SocialSquad simplifies event planning by allowing you to effortlessly set your availability and coordinate with friends, ensuring everyone stays on the same page.",
};

export const MeetUpComponentData = {
  ImageSection: [
    {
      icon: icon1,
      heading: "Reach New People",
      text: "Expand your social circle by discovering and connecting with new attendees at public events.",
    },
    {
      icon: icon2,
      heading: "Continuous Growth",
      text: "Build your community effortlessly with tools that help you grow your network over time.",
    },
    {
      icon: icon3,
      heading: "Simple Hosting Tools",
      text: "Easily create, manage, and host events with intuitive tools designed for simplicity.",
    },
    {
      icon: icon4,
      heading: "Host Safe Events",
      text: "Ensure your events stay secure with safety features that give you peace of mind.",
    },
  ],
};

export const AdvertisementComponentData = {
  AdvertisementArray: [
    {
      image: ad1,
      title: "Save Time",
      body: "Spend less time coordinating meetings and more time focusing on what truly matters to you.",
    },
    {
      image: ad2,
      title: "Stay Organized:",
      body: "Easily keep track of all your meetings, events, and tasks in one convenient place.",
    },
    {
      image: ad3,
      title: "Enhance Productivity",
      body: "Boost your team's efficiency and collaboration with streamlined scheduling and planning.",
    },
  ],
};

export const BootsUpSectionData = {
  img: bike,
  heading: "Give your network a boost",
  body: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
};

export const TopicSectionData = {
  TopicSectionArray: [
    {
      image: group1,
      title: "Explore your city",
      body: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    },
    {
      image: group2,
      title: "Build your career",
      body: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    },
    {
      image: group3,
      title: "Get creative",
      body: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    },
  ],
};

export const CreateGroupData = {
  suggestion: [
    {
      id: "1",
      name: "Family",
    },
    {
      id: "2",
      name: "University group",
    },
    {
      id: "3",
      name: "Gaming",
    },
    {
      id: "4",
      name: "Cricket match",
    },
  ],
};

export const notifyMessage = {
  errorMessage: "Something went wrong. Please try again later.",
};

export const SignUpPageData = {
  genderOptions: [
    { key: "Male", value: "male" },
    { key: "Female", value: "female" },
  ],
};

export const appEmail = {
  email: "admin@socialsquadapp.com",
};

export const metaTags_data = {
  title:
    "Social Squad - Helps you to share your plans with your friends and family.",
  siteName:
    "Social Squad - Helps you to share your plans with your friends and family.",
  description:
    "In this busy world full of social media apps, ever faced difficulty in connecting with your closed ones. Connect with your friends and family, share you plans with them.",
  logo: web_logo,
};

export const categoryOptions = [
  { key: "Shopping", value: 1 },
  { key: "Sports", value: 2 },
  { key: "Meeting", value: 3 },
  { key: "Traveling", value: 4 },
];

export const radioOptions = [
  { key: "In Person", value: "inPerson" },
  { key: "Online", value: "online" },
  { key: "Traveling", value: "traveling" },
];

export const planEventRadioOptions = [
  ...radioOptions.filter((op) => op.value !== "traveling"),
];

export const weekDays = [
  { key: "Mon", isSelected: false },
  { key: "Tue", isSelected: false },
  { key: "Wed", isSelected: false },
  { key: "Thu", isSelected: false },
  { key: "Fri", isSelected: false },
  { key: "Sat", isSelected: false },
  { key: "Sun", isSelected: false },
];

export const statusOptions = [
  { key: "Unavailable", value: 0 },
  { key: "Maybe", value: 1 },
  { key: "Available", value: 2 },
];

export const statusColor = [
  { color: "#ADAFAE", status: 0 },
  { color: "#FFB800", status: 1 },
  { color: "#03DC74", status: 2 },
];

export const COMING_SOON = {
  COVER_IMAGE:
    "https://wallpapers.com/images/featured/blank-white-7sn5o1woonmklx1h.jpg",
  TEXT_HEADING: "COMING SOON",
  TEXT_DETAILS: "Our Website will be live Soon. ",
  GOOGLE_PLAY_URL:
    "https://play.google.com/store/apps/details?id=com.socialsquad.app",
  PHONE_IMAGE:
    "https://innovating.capital/wp-content/uploads/2021/05/vertical-placeholder-image.jpg",
  BUTTON_TEXT: "Click Here",
  WEB_ICON: web_icon,
};

export const WEBSITE_LOGO = {
  logo: web_logo,
};
