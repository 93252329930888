import { useEffect, useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import "../Notification/Notification.scss";
import NotificationCard from "../../components/NotificationCard/NotificationCard";
import ConfigData from "../../config";
import axios from "../../assets/axiosConfig/AxiosConfig";
import moment from "moment";
import Spinner from "../../components/Spinner/Spinner";
import EmptyNotification from "../../assets/images/emptyNotification.png";
import { NotificationContext } from "../../store/NotificationContext";
import MetaTags from "../../Meta/MetaTags";
import useGetUserId from "../../assets/customHooks/getUserId";

function NotificationPage(props) {
  const [getNotificationList, setNotificationList] = useState([]);
  const userId = useGetUserId();
  const [loader, setLoader] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { fetchNotificationList, setFetchNotificationList } =
    useContext(NotificationContext);

  const notifyError = (msg) => toast.error(msg);

  useEffect(() => {
    fetchNotification();
  }, [refresh, fetchNotificationList]);

  const fetchNotification = async () => {
    const notificationState = 0;
    setLoader(true);
    axios
      .get(
        `${ConfigData.getAllUserNotifications}/${userId}/${notificationState}`
      )
      .then((response) => {
        setNotificationList(response.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        notifyError("Something went wrong, please try again later");
        setLoader(false);
      });
  };

  const readAllNotifications = () => {
    axios
      .put(ConfigData.updateReadAllNotifications, {
        read_at: moment(new Date()).utc().format(),
        userId: userId,
      })
      .then(() => {
        setRefresh(!refresh);
        setFetchNotificationList(!fetchNotificationList);
      })
      .catch((error) => {
        notifyError("Something went wrong, please try again later");
        console.log(error);
      });
  };
  return (
    <>
      <MetaTags URL={window.location.href} />
      <Modal
        animationDirection="right"
        fullscreen
        show={props.show}
        onHide={props.onHide}
        className="notification-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="notification-button-section">
              <button
                onClick={readAllNotifications}
                type="button"
                className="btn btn-success mx-2"
              >
                Mark all as read
              </button>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loader ? (
            <Spinner />
          ) : (
            <>
              {getNotificationList?.length > 0 ? (
                getNotificationList?.map((data) => {
                  return (
                    <>
                      <NotificationCard
                        title={data.data.message}
                        date={moment(data.createdAt).fromNow()}
                        readAt={data.read_at}
                        id={data.id}
                        setRefresh={setRefresh}
                        refresh={refresh}
                        icon={data.data.type}
                        notificationType={data.type}
                        receiverId={data.user_id}
                        senderId={data.sender_user_id}
                        event_slug={data.event_slug ? data.event_slug : null}
                        action_performed={data.action_performed}
                      />
                    </>
                  );
                })
              ) : (
                <img
                  src={EmptyNotification}
                  alt="..."
                  className="empty-notification-image rounded mx-auto d-block"
                />
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default NotificationPage;
