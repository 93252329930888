// default 'url' value is set for production and if development localhost url will be used.
let url = window.location.origin + "/";
if (process.env.NODE_ENV === "development") {
  url = "http://localhost:7000/";
  // url="https://socialsquadapp.com/"
}

module.exports = {
  URL: url,
  SignupURL: "api/users",
  LoginURL: "api/auth",
  ForgetPasswordURL: "api/auth/forgetPassword",
  ChangePasswordURL: "api/auth/changePassword",
  VerificationURL: "api/users/verifyCode",
  InvitedEventsURL: "api/events/invitedEvents",
  FetchContactURl: "api/contacts",
  DeleteContactURL: "api/contacts/deleteContact",
  DeleteContactRequestSendURL: "api/contacts/deleteContactRequest",
  getContactDetails: "api/contacts/user/details",
  blockContact: "api/contacts/update/status",
  AddContactsURL: "api/externalContacts",
  AddContactManuallyURL: "api/contacts",
  GroupURL: "api/userGroups",
  GetGroupNameURL: "api/userGroups",
  GetGroupMembersURL: "api/groupMembers",
  FetchGroupURl: "api/userGroups",
  GroupMemberURL: "api/groupMembers",
  GetUserURL: "api/users",
  uploadProfileImageURL: "api/users/uploadImage",
  updateProfileURL: "api/users",
  RemoveAccount: "api/users/removeAccount",

  deleteEventURL: "api/events/deleteEvent",
  joinedInvitedEventsURL: "api/events/joinedInvitedEvents",
  leaveInvitedEventURL: "api/events/leaveInvitedEvent",
  joinInvitedEventURL: "api/events/joinInvitedEvent",
  getEventsURL: "api/events",
  getAnEventURL: "api/events/specific",
  createEventURL: "api/events",
  updateEventURL: "api/events/updateEvent",
  userContactsURL: "api/contacts",
  userGroupsURL: "api/userGroups",
  cancelEventURL: "api/events/cancelEvent",
  restoreEventURL: "api/events/restoreEvent",
  getEventParticipantsURL: "api/eventParticipants",
  getEventGroupsURL: "api/eventGroups",
  GetTravelPlansURL: "api/events/travel",
  FinalizeEventDate: "api/events/finalizeEvent",
  EventDateTimeOptions: "api/eventOptions",
  SpecificInviteStatus: "/api/inviteStatus/specific",
  InviteStatus: "/api/inviteStatus/",

  google: "api/auth/google",
  authSuccess: "api/auth/loginSuccess",
  logoutGoogle: "api/auth/logout",

  FacebookProxyApi: "api/contacts/facebookContacts/proxy",
  GoogleProxyApi: "api/contacts/googleContacts/proxy",
  SaveGoogleContactsURL: "api/contacts/saveGoogleContacts",
  getAllUserNotifications: "api/notifications",
  updateReadNotification: "api/notifications/readNotification",
  updateReadAllNotifications: "api/notifications/readAllNotification",
  getUnreadNotificationURL: "api/notifications/notificationsCount",
  connectionInvite: "api/contacts/connection",
  contactUs: "api/contactUs",

  AvailabilityURL: "api/availabilitySlot",

  commonGroups: "api/userGroups/common/groups",
  currentContactsAvailabilities: "api/availabilitySlot/common/availability",

  addToCalendarApi: "api/events/addToCalendar",
  addFeatureRequest: "api/featureRequest",
};
