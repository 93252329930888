import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import UpcommingEvents from "../../components/UpcommingEvents/UpcommingEvents";
import "../ProfilePage/Profile.scss";
import axios from "../../assets/axiosConfig/AxiosConfig";
import dbConfig from "../../config";
import ProfileImage from "./ProfileImage";
import { toast } from "react-toastify";
import ToastNotification from "../../components/ToastNotification/ToastNotification";
import { notifyMessage as message } from "../../assets/Constants/appConstants";
import MetaTags from "../../Meta/MetaTags";
import useGetUserId from "../../assets/customHooks/getUserId";
import Button from "../../components/Button/Button";
import EditProfileName from "./EditProfileName";
import Loading from "../../components/loading/Loading";
import { Chip, Stack } from "@mui/material";
import { TextField } from "@mui/material";

function Profile(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [notification, setNotification] = useState(false);
  const [editProfileName, setEditProfileName] = useState(false);
  const [refreshProfile, setRefreshProfile] = useState(false);
  const [refreshContactsList, setRefreshContactsList] = useState(false);
  const [contactProfileToggle, setContactProfileToggle] = useState(false);
  const [contactImage, setContactImage] = useState("");
  const [loader, setLoading] = useState(false);
  const [mutualGroups, setMutualGroups] = useState([]);

  const notifyError = (msg) => toast.error(msg || message.errorMessage);
  const notifySuccess = (msg) => toast.success(msg);

  const userId = useGetUserId();

  useEffect(() => {
    fetchUser();
  }, [refreshProfile]);

  const fetchUser = async () => {
    setNotification(false);
    await axios
      .get(`${dbConfig.GetUserURL}/${userId}`)
      .then((res) => {
        // '?' <- optional chaining
        setName(res.data?.name);
        setEmail(res.data?.email);
      })
      .catch((err) => {
        setNotification(true);
        notifyError(err.response?.data);
      });
  };

  const onSubmit = (values) => {
    if (name !== values.name) {
      axios
        .put(`${dbConfig.updateProfileURL}`, {
          name: values.name,
          email: email,
          userId: userId,
        })
        .then((res) => {
          setName(values.name);
          setRefreshProfile(!refreshProfile);
          notifySuccess(res.data);
          setEditProfileName(false);
        })
        .catch((err) => {
          notifyError(err.response.data);
        });
    } else {
      setEditProfileName(false);
      return;
    }
  };

  const handleMutualGroups = async (contactId, contactEmail) => {
    await axios
      .get(`${dbConfig.commonGroups}`, {
        params: {
          participantId: contactId,
          userId: userId,
          contactEmail: contactEmail,
        },
      })
      .then((res) => {
        setMutualGroups(res.data);
      })
      .catch((err) => {
        setNotification(true);
        notifyError(err.response?.data);
      });
  };

  const handleContactProfile = async (contactId, contactEmail) => {
    setLoading(true);
    await axios
      .get(`${dbConfig.getContactDetails}/${contactId}`)
      .then((res) => {
        setName(res.data?.name);
        setEmail(res.data?.email);
        setContactImage(res.data?.profile_image);
        setContactProfileToggle(true);
        handleMutualGroups(contactId, contactEmail);
        setLoading(false);
      })
      .catch((err) => {
        setNotification(true);
        notifyError(err.response?.data);
      });
  };

  const handleContactBlock = async (contactId, setBlock = 1) => {
    await axios
      .put(`${dbConfig.blockContact}`, {
        setBlock: setBlock,
        contactId: contactId,
        addedById: userId,
      })
      .then((res) => {
        notifySuccess(res.data);
        setRefreshContactsList(!refreshContactsList);
      })
      .catch((err) => {
        setNotification(true);
        notifyError(err.response?.data);
      });
  };

  if (loader)
    return (
      <div className="text-center mt-3">
        <Loading />
      </div>
    );

  return (
    <>
      <MetaTags URL={window.location.href} />
      {notification && <ToastNotification />}
      <div
        className="container my-4"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <div className="card">
          <div className="row">
            <div className="col-lg-6 col-md-12 my-4">
              {contactProfileToggle ? (
                <Button
                  className="btn btn-success mx-2"
                  text="Back"
                  onClick={() => {
                    setRefreshProfile(!refreshProfile);
                    setContactProfileToggle(!contactProfileToggle);
                    setContactImage(null);
                  }}
                />
              ) : null}
              <div className="my-md-5 d-flex justify-content-center">
                <ProfileImage
                  setUserPic={props.setUserPic}
                  userPic={props.userPic}
                  contactImage={contactImage}
                  contactProfileToggle={contactProfileToggle}
                />
              </div>
            </div>
            {editProfileName ? (
              <EditProfileName
                email={email}
                name={name}
                onSubmit={onSubmit}
                setEditProfileName={setEditProfileName}
              />
            ) : (
              <div className="col-lg-6 col-md-12 my-auto mx-auto">
                <div class="card-body mx-sm-5">
                  <div>
                    <div className="d-flex col-12">
                      <TextField
                        id="outlined-basic"
                        label="Name"
                        variant="outlined"
                        value={name}
                        disabled
                        className="col-12 my-3"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="d-flex col-12">
                      <TextField
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        value={email}
                        disabled
                        className="col-12 my-3"
                      />
                    </div>
                  </div>
                  {!contactProfileToggle ? (
                    <div className="text-end">
                      <Button
                        text="Edit profile"
                        onClick={() => setEditProfileName(true)}
                        className="btn btn-success"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {contactProfileToggle ? (
        <div className="container mt-3">
          <h4 className="ps-3">Mutual Groups:</h4>
          {mutualGroups.length > 1 ? (
            <Stack direction="row" spacing={1} className="col-12 py-2 border">
              {mutualGroups.map((group) => (
                <Chip label={group.name} variant="outlined" />
              ))}
            </Stack>
          ) : (
            <p className="text-center pt-3">
              You don't have any mutual groups with this contact
            </p>
          )}
        </div>
      ) : (
        <UpcommingEvents
          email={email}
          handleContactProfile={handleContactProfile}
          handleContactBlock={handleContactBlock}
          refreshContactsList={refreshContactsList}
          setRefreshContactsList={setRefreshContactsList}
        />
      )}
    </>
  );
}

export default Profile;
