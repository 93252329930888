import React from "react";

function Spinner() {
  return (
    <div className="mt-5">
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default Spinner;
