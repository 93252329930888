import React, { useState, useContext, useEffect } from "react";
import { Avatar } from "@mui/material";
import AuthContext from "../../store/AuthContext";
import DefaultImage from "../../assets/images/defaultImage.jpg";

function ProfileImageSrcComponent(props) {
  const authCtx = useContext(AuthContext);
  const [image, setImage] = useState(localStorage.getItem("image"));
  useEffect(() => {
    setImage(
      props.contactProfileToggle
        ? props.ParentImage
        : localStorage.getItem("image")
    );
  }, [authCtx.image, authCtx.login, props.ParentImage]); // change image according to change in these 3 mentioned properties
  return (
    <Avatar
      src={
        image === "" ||
        image === null ||
        image === undefined ||
        image === "undefined" //! Somewhere in the code it is defining the image as "undefined" string, so need to add a check for it
          ? DefaultImage
          : image
      }
      {...props}
    />
  );
}

export default ProfileImageSrcComponent;
