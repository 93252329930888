import React, { useState, useEffect } from "react";
import UserTable from "../../components/UserTable/UserTable";
import Axios from "axios";
import "./AdminPage.scss";
import dbConfig from "../../config";
import MetaTags from "../../Meta/MetaTags";

const my_buttons = [
  { id: 1, label: "All Users (200)" },
  { id: 2, label: "Blocked User (60)" },
];
function User() {
  const [userList, setUserList] = useState(true);
  const [UserActive, setUserActive] = useState([{}]);
  const [UserBlocked, setBlockedActive] = useState([{}]);
  const [buttonState, setButton] = useState(1);
  const userActiveHandler = () => {
    setUserList(true);
    setUserList(false);
  };
  const userBlockedHandler = () => {
    setUserList(false);
    setUserList(true);
  };
  useEffect(() => {
    Axios.get(`${dbConfig.URL}/user/active/user`)
      .then((res) => {
        setUserActive(res.data);
      })
      .catch((err) => {
        console.log("server not connected");
      });
    Axios.get(`${dbConfig.URL}/user/blocked/user`)
      .then((res) => {
        setBlockedActive(res.data);
      })
      .then((err) => {
        console.log("server not connected");
      });
  });

  return (
    <>
      <MetaTags URL={window.location.href} />
      <div>
        <h1 class="display-6">Users</h1>
        <div class="d-flex justify-content-start my-3 ">
          {my_buttons.map((btn) => (
            <button
              type="button"
              className={
                buttonState === btn.id
                  ? "customBtn customBtn_active me-4"
                  : "customBtn me-4"
              }
              onClick={() => {
                buttonState === 1 ? userActiveHandler() : userBlockedHandler();
                buttonState === 2 ? userActiveHandler() : userBlockedHandler();
                console.log(userList);
                console.log(btn.id);
                setButton(btn.id);
              }}
            >
              {btn.label}
            </button>
          ))}
        </div>

        {userList ? (
          <div>
            <h2 class="text-center">Active Users</h2>
            <UserTable
              UserList={UserActive}
              state={userList}
              btnColor={"red"}
            ></UserTable>
          </div>
        ) : (
          <div>
            <h2 class="text-center">Blocked Users </h2>
            <UserTable UserList={UserBlocked} btnColor={"#288754"}></UserTable>
          </div>
        )}
      </div>
    </>
  );
}

export default User;
