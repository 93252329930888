import React from "react";
import { Helmet } from "react-helmet";
import { metaTags_data } from "../assets/Constants/appConstants";

const MetaTags = ({ title, siteName, description, logo, URL }) => {
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: title || metaTags_data.title,
    description: description || metaTags_data.description,
    url: URL || window.location.origin,
    image: logo || metaTags_data.logo,
    publisher: {
      "@type": "Organization",
      name: siteName || metaTags_data.siteName,
    },
  };

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content={description || metaTags_data.description}
      />
      <meta name="image" content={logo || metaTags_data.logo} />
      <meta name="url" content={URL || window.location.origin} />

      <meta property="og:title" content={title || metaTags_data.title} />
      <meta
        property="og:site_name"
        content={siteName || metaTags_data.siteName}
      />
      <meta property="og:url" content={URL || window.location.origin} />
      <meta
        property="og:description"
        content={description || metaTags_data.description}
      />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={logo || metaTags_data.logo} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={URL || window.location.origin} />
      <meta property="twitter:title" content={title || metaTags_data.title} />
      <meta
        property="twitter:description"
        content={description || metaTags_data.description}
      />
      <meta property="twitter:image" content={logo || metaTags_data.logo} />

      <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
    </Helmet>
  );
};

export default MetaTags;
