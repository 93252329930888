import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import AuthContext from "./store/AuthContext";

function ProtectedRoute({ isLoggedIn, component: Component, ...rest }) {
  const UserContext = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(otherProps) => {
        if (isLoggedIn && localStorage.getItem("token")) {
          return <Component {...otherProps} {...rest} />;
        } else {
          UserContext.logout();
          <Redirect to={{ path: "/" }} />;
        }
        if (!isLoggedIn) return <Redirect to={{ path: "/" }} />;
      }}
    />
  );
}

export default ProtectedRoute;
