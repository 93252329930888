import React, { useEffect, useState } from "react";
import DeleteModal from "../UpcommingEvents/DeleteModal";
import Axios from "axios";
import DataTable from "react-data-table-component";
import "../UserTable/UserTable.scss"
import dbConfig from "../../config"

function UserTable(props) {
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchData,setSearchData]=useState("");
  const [filterData,setFilterData]=useState([]);
  useEffect(()=>{
    setFilterData(props.UserList);
  },[props.UserList]);
  const [getId, setID] = useState(0);
  const deleteEventHandler = (user_id) => {
    setID(user_id);
    setDeleteModal(true);
  };
  const BlockedUserHandler = () => {
    Axios.put(`${dbConfig.URL}/user/blocked/${getId}`).then((res) => {
    });
  };
  const UnBlockedUserHandler = () => {
    Axios.put(`${dbConfig.URL}/user/unblocked/${getId}`).then((res) => {
    });
  };
  const column = [
    // user_id, email, name, gender
    {
      name: "User Name",
      id:"name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "User Email",
      id:"email",
      selector: (row) => row.email,
    },
    {
      name: "Gender",
      id:"gender",
      selector: (row) => row.gender,
    },
    {
      name: "Action",
      id:"action",
      cell: (row) => (
        <button
          type="button"
          class="btn btn-sm "
          onClick={() => {
            deleteEventHandler(row.user_id);
          }}
          style={{
            backgroundColor: `${props.btnColor}`,
            color: "white",
          }}
        >
          {props.state ? <span>Block</span> : <span>UnBlock</span>}
        </button>
      ),
    },
  ];
  return (
    <>
      <DataTable
        columns={column}
        data={filterData}
        pagination
        fixedHeader
        highlightOnHover
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="Search here"
            className="w-25 form-control"
            value={searchData}
            onChange={(e)=>{
              setSearchData(e.target.value);
            }}
          ></input>
        }
        subHeaderAlign="left"
      />

      {deleteModal && (
        <DeleteModal
          setDeletModal={setDeleteModal}
          title={props.state ? "Block the User" : "Unblock the User"}
          function={props.state ? BlockedUserHandler : UnBlockedUserHandler}
          functionState={true}
          userState={props.state}
          btnColor={props.btnColor}
          deleteModal={deleteModal}
        />
      )}
    </>
  );
}

export default UserTable;
