import { useEffect, useContext } from "react";
import Pusher from "pusher-js";
import AuthContext from "../../store/AuthContext";
import jwtDecode from "jwt-decode";
import { NotificationContext } from "../../store/NotificationContext";

function PusherHelper({ setIsVisible, userId }) {
  const authCtx = useContext(AuthContext);
  const { fetchNotificationList, setFetchNotificationList } =
    useContext(NotificationContext);

  const pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  });

  const user_id =
    userId == null
      ? authCtx.token
        ? jwtDecode(authCtx.token).id
        : userId
      : userId;

  useEffect(() => {
    const eventCreateName = `eventCreate${user_id}`;
    const eventDeletedName = `eventDelete${user_id}`;
    const eventJoinName = `eventJoin${user_id}`;
    const eventLeaveName = `eventLeave${user_id}`;
    const eventEditName = `eventEdit${user_id}`;
    const connectionRequests = `inviteRequestNotification${user_id}`;

    const channel = pusher.subscribe(eventCreateName);
    const eventDeleted = pusher.subscribe(eventDeletedName);
    const eventJoin = pusher.subscribe(eventJoinName);
    const eventLeave = pusher.subscribe(eventLeaveName);
    const eventEdit = pusher.subscribe(eventEditName);
    const connectionInvites = pusher.subscribe(connectionRequests);

    const handleNotification = (data) => {
      setIsVisible(true);
      setFetchNotificationList((prev) => !prev);
    };

    channel.bind("my-event", handleNotification);
    eventDeleted.bind("my-event", handleNotification);
    eventJoin.bind("my-event", handleNotification);
    eventLeave.bind("my-event", handleNotification);
    eventEdit.bind("my-event", handleNotification);
    connectionInvites.bind("my-event", handleNotification);

    return () => {
      // Unsubscribe from channels when component unmounts
      pusher.unsubscribe(eventCreateName);
      pusher.unsubscribe(eventDeletedName);
      pusher.unsubscribe(eventJoinName);
      pusher.unsubscribe(eventLeaveName);
      pusher.unsubscribe(eventEditName);
      pusher.unsubscribe(connectionRequests);
    };
  }, [fetchNotificationList]);

  return null;
}

export default PusherHelper;
