import { useContext, useEffect } from "react";
import AuthContext from "../../store/AuthContext";
import jwtDecode from "jwt-decode";

function useGetUserId() {
  const authCtx = useContext(AuthContext);
  let userId;
  userId = jwtDecode(authCtx.token).id;

  return userId;
}

export default useGetUserId;
