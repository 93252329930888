import "./App.css";
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Layout from "./components/Layout";
import ProtectedRoute from "./ProtectedRoute";
import AuthContext from "./store/AuthContext";
import ConfigData from "./config";
import axios, { setAxiosToken } from "./assets/axiosConfig/AxiosConfig";
import jwtDecode from "jwt-decode";
import UserContext from "./store/UserContext";
import { NotificationProvider } from "./store/NotificationContext";
import MetaTags from "./Meta/MetaTags";
import { metaTags_data } from "./assets/Constants/appConstants";
import PusherHelper from "./assets/helper/pusherHelper";
import Loading from "./components/loading/Loading";

import Profile from "./Pages/ProfilePage/Profile";
import User from "./Pages/AdminPage/User";

//Lazy Loading Component Starts here
const HomePage = lazy(() => import("./Pages/HomePage/HomePage"));
const LoginPage = lazy(() => import("./Pages/LoginPage/LoginPage"));
const SignUp = lazy(() => import("./Pages/SignUpPage/SignUpPage"));
// const Profile = lazy(() => import("./Pages/ProfilePage/Profile")); //!Don't lazy load it
const ActionPlanPage = lazy(() =>
  import("./Pages/ActionPlanPage/ActionPlanPage")
);
const ContactList = lazy(() => import("./Pages/ContactListPage/ContactList"));
const SideBar = lazy(() => import("./components/SideBar/SideBar"));
// const User = lazy(() => import("./Pages/AdminPage/User")); //!Don't lazy load it
const Event = lazy(() => import("./Pages/AdminPage/Event"));
const ShowContact = lazy(() => import("./components/ShowContact/ShowContact"));
const JoinEvents = lazy(() => import("./Pages/JoinEvents/JoinEvents"));
const EventDetails = lazy(() =>
  import("./Pages/EventDetailsPage/EventDetails")
);
const PrivacyPolicy = lazy(() =>
  import("./components/PrivacyPolicy/PrivacyPolicy")
);
const ForgetPassword = lazy(() =>
  import("./components/ForgetPassword/ForgetPassword")
);
const PasswordReset = lazy(() =>
  import("./components/ForgetPassword/PasswordReset")
);
const NotificationPage = lazy(() =>
  import("./Pages/Notification/NotificationPage")
);
const Settings = lazy(() => import("./Pages/Settings/Settings"));
const Availability = lazy(() =>
  import("./Pages/AvailabilityPage/Availability")
);
const ContactUs = lazy(() => import("./Pages/ContactUsPage/ContactUs"));
const EmailVerification = lazy(() =>
  import("./Pages/EmailVerification/EmailVerification")
);
const FeatureRequest = lazy(() =>
  import("./Pages/FeatureRequest/FeatureRequest")
);
const ComingSoon = lazy(() => import("./Pages/ComingSoon/ComingSoon"));

function App() {
  const UserContextStore = useContext(AuthContext);

  const { isLoggedIn, isVerified } = UserContextStore;
  const [userPic, setUserPic] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    axios
      .get(ConfigData.authSuccess, {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data?.message) {
          setLoader(false);
          return;
        } else {
          const expirationTime = new Date(
            new Date().getTime() + +res.data.expiresIn * 1000
          );
          UserContextStore.login(res.data.token, expirationTime);
          const userImage = jwtDecode(res.data.token).profile_image;
          localStorage.setItem("image", userImage);
          if (res.data.google_id) {
            UserContextStore.googleHandler(res.data.google_id);
          }
          setUserPic(!userPic);
          setAxiosToken();
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    if (
      process.env.NODE_ENV === "production" ||
      process.env.ENVIRONMENT === "live"
    ) {
      console.log = () => {};
      console.warn = () => {};
      console.debug = () => {};
      console.error = () => {};
      console.info = () => {};
      console.time = () => {};
    }
  }, []);

  return (
    <>
      {loading ? (
        <div className="text-center mt-5 pt-5">
          <Loading />
        </div>
      ) : (
        <>
          <MetaTags
            title={metaTags_data.title}
            siteName={metaTags_data.siteName}
            description={metaTags_data.description}
            logo={metaTags_data.logo}
            URL={window.location.href}
          />

          <Suspense fallback={<Loading />}>
            <UserContext.Provider value={{ userId, setUserId }}>
              <NotificationProvider>
                {UserContextStore.getEmail === "admin@gmail.com" ? (
                  <>
                    <SideBar />
                    <Switch>
                      <Route exact path="/users" component={User} />
                      <Route exact path="/events" component={Event} />
                    </Switch>
                  </>
                ) : (
                  <>
                    <Layout userPic={userPic}>
                      {/* <Route exact path="/*" component={ComingSoon} /> */}
                      <Route
                        path="/"
                        exact
                        render={(props) => (
                          <HomePage {...props} isVisible={isVisible} />
                        )}
                      />
                      <Route
                        path="/privacy-policy"
                        exact
                        component={PrivacyPolicy}
                      />
                      {/* <Route exact path="/contact-us" component={ContactUs} /> */}
                      {/* <Switch>
                        <Route
                          path="/"
                          exact
                          render={(props) => (
                            <HomePage {...props} isVisible={isVisible} />
                          )}
                        />
                        <Route
                          path="/privacy-policy"
                          exact
                          component={PrivacyPolicy}
                        />
                        <Route exact path="/contact-us" component={ContactUs} />
                        <Route exact path="/signUp" component={SignUp} />
                        <Route
                          exact
                          path="/signUp/:invitedByUserId"
                          component={SignUp}
                        />
                        <Route exact path="/login" component={LoginPage} />
                        <Route
                          exact
                          path="/forget-password"
                          component={ForgetPassword}
                        />
                        <Route
                          exact
                          path="/password-reset/:resetToken"
                          component={PasswordReset}
                        />
                        <Route
                          exact
                          path="/verify"
                          component={EmailVerification}
                        />
                        <ProtectedRoute
                          path="/event-details/:slug"
                          component={(props) => (
                            <EventDetails
                              key={props.match.params.slug}
                              {...props}
                            />
                          )}
                          isLoggedIn={isLoggedIn}
                        />
                        <ProtectedRoute
                          path="/profile"
                          setUserPic={setUserPic}
                          userPic={userPic}
                          isLoggedIn={isLoggedIn}
                          component={Profile}
                        />
                        <ProtectedRoute
                          path="/create-group"
                          component={ContactList}
                          isLoggedIn={isLoggedIn}
                        />
                        <ProtectedRoute
                          path="/contact-list"
                          component={ShowContact}
                          isLoggedIn={isLoggedIn}
                        />
                        <ProtectedRoute
                          path="/event-plan"
                          component={ActionPlanPage}
                          isLoggedIn={isLoggedIn}
                        />
                        <ProtectedRoute
                          path="/join-events"
                          component={JoinEvents}
                          isLoggedIn={isLoggedIn}
                        />
                        <ProtectedRoute
                          path="/notifications"
                          component={NotificationPage}
                          isLoggedIn={isLoggedIn}
                        />
                        <ProtectedRoute
                          path="/settings"
                          component={Settings}
                          isLoggedIn={isLoggedIn}
                        />
                        <ProtectedRoute
                          path="/user-availability"
                          component={Availability}
                          isLoggedIn={isLoggedIn}
                        />
                        <ProtectedRoute
                          path="/feature-request"
                          component={FeatureRequest}
                          isLoggedIn={isLoggedIn}
                        />
                        <Route path="*" component={NotFound} />
                      </Switch> */}
                    </Layout>
                    {isLoggedIn ? (
                      <PusherHelper
                        setIsVisible={setIsVisible}
                        userId={userId}
                      />
                    ) : null}
                  </>
                )}
              </NotificationProvider>
            </UserContext.Provider>
          </Suspense>
        </>
      )}
    </>
  );
}

// NotFound component
const NotFound = () => (
  <div className="p-5 d-flex flex-column align-items-center">
    <h2>404 Not Found</h2>
    <p>The page you are looking for does not exist.</p>
  </div>
);

export default App;
