import Lottie from "lottie-react";

function LottieAnimationConfig(props) {
  return (
    <div className="d-flex justify-content-center">
      <Lottie
        animationData={props.animation}
        loop={props.loop}
        style={{ width: props.width }}
        {...props}
      />
    </div>
  );
}

export default LottieAnimationConfig;
