import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import bellIcon from "../../assets/images/bell.png";
import { Link, useHistory } from "react-router-dom";
import "../Header/Header.scss";
import AuthContext from "../../store/AuthContext";
import { Menu, MenuItem } from "@mui/material";
import { ClickAwayListener } from "@mui/material";
import ProfileImageSrcComponent from "../ProfileImageSrcComp.jsx/ProfileImageSrcComponent";
import NotificationPage from "../../Pages/Notification/NotificationPage";
import jwt from "jwt-decode";
import ConfigData from "../../config";
import axios from "../../assets/axiosConfig/AxiosConfig";
import UserContext from "../../store/UserContext";
import Badge from "@mui/material/Badge";
import { NotificationContext } from "../../store/NotificationContext";
import { WEBSITE_LOGO } from "../../assets/Constants/appConstants";

function Header(props) {
  const authCtx = useContext(AuthContext);
  const [image, setImage] = useState(authCtx.image);
  const [showDropdown, setShowDropdown] = useState(false);
  const history = useHistory();
  const { fetchNotificationList } = useContext(NotificationContext);
  // Notification
  const [getNotificationListCount, setNotificationListCount] = useState();
  const { userId } = useContext(UserContext);

  const user_id =
    userId == null ? (authCtx.token ? jwt(authCtx.token).id : userId) : userId;

  useEffect(() => {
    if (authCtx.isLoggedIn && user_id) {
      fetchNotification();
    }
  }, [authCtx.isLoggedIn]);

  useEffect(() => {
    if (authCtx.isLoggedIn && user_id) {
      fetchNotification();
    }
  }, [fetchNotificationList]);

  const fetchNotification = async () => {
    axios
      .get(`${ConfigData.getUnreadNotificationURL}`, {
        params: { userId: user_id },
      })
      .then((response) => {
        const length = response.data.length > 0 ? response.data.length : 0;
        setNotificationListCount(length);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [show, setShow] = useState(false);

  const closeNotificationModal = () => setShow(false);
  const showNotificationModal = () => setShow(true);
  useEffect(() => {
    setImage(localStorage.getItem("image"));
  }, [props.userPic]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCLickProfile = () => {
    history.push("/profile");
    setAnchorEl(null);
  };
  const onClickSettings = () => {
    history.push("/settings");
    setAnchorEl(null);
  };
  const onCLickLogout = () => {
    authCtx.logout();
    setAnchorEl(null);
  };

  const onCLickAvailability = () => {
    history.push("/user-availability");
    setAnchorEl(null);
  };

  return (
    <>
      <div className="container">
        <Navbar className="p-1" collapseOnSelect expand="lg" variant="light">
          <Navbar.Brand href="#home">
            <Link to="/">
              <img
                src={WEBSITE_LOGO.logo}
                alt="Social Squad"
                style={{ width: 130 }}
              />
            </Link>
          </Navbar.Brand>
          {/* <div className="d-flex gap-2">
            {authCtx.isLoggedIn ? (
              <div
                className="d-flex align-items-center d-lg-none"
                style={{ marginRight: 20 }}
              >
                <Badge badgeContent={getNotificationListCount} color="error">
                  <img
                    onClick={showNotificationModal}
                    src={bellIcon}
                    alt="bell icon "
                    style={{ width: 28 }}
                  />
                </Badge>
              </div>
            ) : null}
            <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto container-fluid justify-content-end align-items-center">
              {authCtx.isLoggedIn ? (
                <>
                  <Nav.Link>
                    <Link to="/create-group" className="header-link">
                      Groups
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link to="/event-plan" className="header-link me-lg-2">
                      Events
                    </Link>
                  </Nav.Link>
                  <div className="d-block d-lg-none">
                    <Nav.Link>
                      <Link
                        to="/user-availability"
                        className="header-link me-lg-2"
                      >
                        Availability
                      </Link>
                    </Nav.Link>
                  </div>
                  <div className="d-none d-lg-block">
                    <Nav.Link>
                      <div style={{ marginRight: 20 }}>
                        <Badge
                          badgeContent={getNotificationListCount}
                          color="error"
                        >
                          <img
                            onClick={showNotificationModal}
                            src={bellIcon}
                            alt="bell icon"
                            style={{ width: 30 }}
                          />
                        </Badge>
                      </div>
                    </Nav.Link>
                  </div>
                  <div className="text-center d-lg-none">
                    <Nav.Link>
                      <Link to="/settings" className="header-link">
                        Settings
                      </Link>
                    </Nav.Link>
                    <Nav.Link>
                      <Link
                        className="header-link"
                        to="/settings"
                        onClick={() => authCtx.logout()}
                      >
                        Logout
                      </Link>
                    </Nav.Link>
                    <Nav.Link>
                      <Link to="/profile" className="header-link">
                        <ProfileImageSrcComponent
                          ParentImage={image}
                          style={{ width: "60px", height: "60px" }}
                          className="img-fluid rounded-circle border border-dark border-3"
                          alt="Generic placeholder image"
                        />
                      </Link>
                    </Nav.Link>
                  </div>
                  <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
                    <div className="d-none d-lg-block">
                      <ProfileImageSrcComponent
                        ParentImage={image}
                        alt="Generic placeholder image"
                        id="demo-positioned-button"
                        aria-controls={
                          open ? "demo-positioned-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        style={{ width: "50px", height: "50px" }}
                        className="img-fluid rounded-circle border border-dark border-2 avatarClass"
                      />
                      <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem onClick={onCLickProfile}>Profile</MenuItem>
                        <MenuItem onClick={onCLickAvailability}>
                          Availability
                        </MenuItem>
                        <MenuItem onClick={onClickSettings}>Settings</MenuItem>
                        <MenuItem onClick={onCLickLogout}>Logout</MenuItem>
                      </Menu>
                    </div>
                  </ClickAwayListener>
                </>
              ) : (
                <>
                  <Nav.Link>
                    <Link to="/signup" className="header-link">
                      Sign Up
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link to="/login" className="header-link">
                      Login
                    </Link>
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse> */}
        </Navbar>
      </div>
      {/* {show && <NotificationPage show={show} onHide={closeNotificationModal} />} */}
    </>
  );
}

export default Header;
