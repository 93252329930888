import React, { useContext } from "react";
import "../../Pages/Notification/Notification.scss";
import select from "../../assets/images/select.png";
import Decline from "../../assets/images/crossIcon.png";
import axios from "../../assets/axiosConfig/AxiosConfig";
import ConfigData from "../../config";
import moment from "moment";
import { NotificationContext } from "../../store/NotificationContext";
import { useState } from "react";
import { useEffect } from "react";
import bellIcon from "../../assets/images/bell.png";
import meeting from "../../assets/images/meeting.png";
import location from "../../assets/images/location.png";
import world from "../../assets/images/world.png";
import "../../Pages/Notification/Notification.scss";
import { Link, useHistory } from "react-router-dom";

function NotificationCard(props) {
  const history = useHistory(); // Initialize the useHistory hook
  const [icon, setIcon] = useState("");
  useEffect(() => {
    if (props.icon == "online") {
      setIcon(meeting);
    } else if (props.icon == "inPerson") {
      setIcon(location);
    } else if (props.icon == "traveling") {
      setIcon(world);
    } else {
      setIcon(bellIcon);
    }
  }, []);
  const { fetchNotificationList, setFetchNotificationList } =
    useContext(NotificationContext);

  const readNotificationHandler = async () => {
    axios
      .put(ConfigData.updateReadNotification, {
        notificationId: props.id,
        read_at: moment(new Date()).utc().format(),
        action_performed: true,
      })
      .then((response) => {
        props.setRefresh(!props.refresh);
        setFetchNotificationList(!fetchNotificationList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleConnectionInvite = async (receiverId, senderId, status) => {
    readNotificationHandler();
    axios
      .put(ConfigData.connectionInvite, {
        user_id: receiverId,
        added_by: senderId,
        status: status,
      })
      .then((response) => {
        props.setRefresh(!props.refresh);
        setFetchNotificationList(!fetchNotificationList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNavigateButton = () => {
    history.push(`/event-details/${props.event_slug}`);
    // window.location.reload();
  };

  return (
    <>
      <div
        className="d-flex flex-row justify-content-between  align-items-end notification-card"
        style={
          props.readAt ? null : { backgroundColor: "rgba(252, 92, 101, 0.1)" }
        }
      >
        <div className="d-flex flex-row">
          <div>
            <img src={icon} alt="bell icon " style={{ width: 50 }} />
          </div>
          <div style={{ marginLeft: 20 }}>
            <p className="notification-card-heading">{props.title}</p>
            <p className="notification-card-text">{props.date}</p>
            {props.event_slug ? (
              <>
                <button
                  onClick={handleNavigateButton}
                  className="btn btn-primary"
                >
                  Go to Event
                </button>
              </>
            ) : null}
          </div>
        </div>

        {props.notificationType === "connectionInviteNotification" ? (
          <div className="d-flex">
            <img
              onClick={() =>
                handleConnectionInvite(props.receiverId, props.senderId, 0)
              }
              src={Decline}
              alt="decline request"
              className={
                props.action_performed === false
                  ? "notification-select-icon"
                  : "d-none"
              }
              style={{ width: 30 }}
            />
            <img
              onClick={() =>
                handleConnectionInvite(props.receiverId, props.senderId, 2)
              }
              src={select}
              alt="accept request"
              className={
                props.action_performed === false
                  ? "notification-select-icon"
                  : "d-none"
              }
              style={{ width: 30, marginLeft: "0.25rem" }}
            />
          </div>
        ) : (
          <div onClick={props.readAt == null && readNotificationHandler}>
            <img
              src={select}
              alt="read notification"
              className={
                props.readAt == null ? "notification-select-icon" : "d-none"
              }
              style={{ width: 30 }}
            />
          </div>
        )}
      </div>

      <div className="notification-card-separator"></div>
    </>
  );
}

export default NotificationCard;
